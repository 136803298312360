/* Styles from the provided custom-theme.css */
body {
  background-color: #0d1117;
  color: #c9d1d9;
}

.Box--dark {
  background-color: #0d1117;
  border-color: #30363d;
}

.Box--gray {
  background-color: #161b22;
}

.text-white {
  color: #c9d1d9;
}

.default-blue-border {
  border: 1px solid #30363d; /* Set initial border color */
  padding: 6px 12px;
  border-radius: 6px;
  background-color: #0d1117;
  color: #c9d1d9;
}

.default-blue-border:focus {
  border-color: #4493f8; /* Set border color on focus */
  outline: none; /* Remove default outline */
}
.text-blue {
  color: #58a6ff;
}

.Button-primary {
  background-color: #58a6ff;
  border-color: #58a6ff;
  color: #ffffff;
}

.Flash-success {
  background-color: #28a745;
  color: #ffffff;
}

.container {
  max-width: 60%;
  margin: 0 auto;
  padding: 16px;
}


.title {
  color: #c9d1d9;
  font-size: 24px;
  margin-bottom: 16px;
}

.card {
  background-color: #161b22;
  color: #c9d1d9;
  border: 1px solid #30363d;
  border-radius: 6px;
  padding: 16px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.tab-container {
  display: flex;
  margin-bottom: 16px;
}

.tab {
  padding: 8px 16px;
  cursor: pointer;
  background-color: transparent;
  color: #58a6ff;
  border: 1px solid #30363d;
  border-bottom: 1px solid #30363d;
  border-radius: 6px 6px 0 0;
}

.tab:hover {
  background-color: #0d1117;
  color: #c9d1d9;
}

.tab.active {
  background-color: #0d1117;
  color: #c9d1d9;
  border-bottom: 1px solid #0d1117;
}

.tab-content {
  padding: 16px;
  background-color: #0d1117;
  border: 1px solid #30363d;
  border-radius: 0 6px 6px 6px;
}

/* GitHub button style */
.github-button {
  background-color: #24292e;
  color: #d2d2d2;
  border: none;
  padding: 6px 16px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Symbol";
  margin-right: 16px;
  transition: background-color 0.2s ease-in-out;
}

.github-button:hover {
  background-color: #0d0d0d;
  cursor: pointer;
}

/* Blue button style */
.blue-button {
  background-color: #0366d6; /* GitHub blue */
  color: #ffffff;
  border: 1px solid #0366d6;
  border-radius: 6px;
  padding: 6px 16px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Symbol";
  transition: background-color 0.2s ease-in-out;
}

.blue-button:hover {
  background-color: #005cc5; /* Darker blue on hover */
  cursor: pointer;
}

/* Green button style */
.green-button {
  background-color: #28a745;
  color: #ffffff;
  border: 1px solid #28a745;
  border-radius: 6px;
  padding: 6px 16px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Symbol";
  transition: background-color 0.2s ease-in-out;
}

.green-button:hover {
  background-color: #218838; /* Darker green on hover */
  cursor: pointer;
}

.green-button.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Hidden input style */
.hidden-input {
  display: none;
}

/* Add this to your CSS file or within a <style> tag in the component file */

body {
  background-color: #0d1117;
  color: #c9d1d9;
}

.menu-container {
  font-family: Arial, sans-serif;
  padding: 20px;
  background-color: #0d1117;
}

.menu-heading {
  font-size: 24px;
  color: #c9d1d9;
  margin-bottom: 20px;
}

.menu-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  background-color: #161b22;
  border: 1px solid #30363d;
  box-shadow: 0 1px 3px rgba(27, 31, 35, 0.12);
}

.menu-table th,
.menu-table td {
  border: 1px solid #30363d;
  padding: 8px;
  text-align: left;
}

.menu-table th {
  background-color: #21262d;
  color: #c9d1d9;
}

.menu-table td {
  color: #c9d1d9;
}

.menu-table img {
  width: 50px;
  border-radius: 4px;
}

.menu-actions {
  display: flex;
  flex-direction: column;
  gap: 5px; /* Small gap between buttons */
}

.menu-actions button {
  margin-bottom: 5px;
  padding: 8px;
  border: none;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
}

.menu-actions .update-btn {
  background-color: #ff7b00; /* Orange color for update button */
}

.menu-actions .delete-btn {
  background-color: #d73a49; /* Red color for delete button */
}

.menu-actions button:hover {
  opacity: 0.9;
}

.menu-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #161b22;
  padding: 20px;
  border-radius: 6px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  display: none;
  color: #c9d1d9;
  width: 400px;
}

.menu-modal-active {
  display: block;
}

.menu-modal-header {
  font-size: 20px;
  margin-bottom: 10px;
  color: #c9d1d9;
  text-align: center;
}

.menu-modal-body {
  display: flex;
  flex-direction: column;
  gap: 10px; /* Gap between input elements */
}

.menu-modal-body label {
  display: block;
  color: #c9d1d9;
}

.menu-modal-body input[type="text"],
.menu-modal-body input[type="file"] {
  width: 100%;
  padding: 8px;
  border: 1px solid #30363d;
  border-radius: 4px;
  background-color: #0d1117;
  color: #c9d1d9;
}

.menu-modal-body input[type="checkbox"] {
  margin-right: 5px;
  background-color: #161b22;
  border: 1px solid #30363d;
  color: #c9d1d9;
}

.menu-modal-body .menu-checkbox-label {
  display: flex;
  align-items: center;
}

.menu-modal-body .menu-image-preview {
  display: block;
  margin-top: 10px;
  width: 100%;
  border-radius: 4px;
}

.menu-modal-footer {
  margin-top: 10px;
  text-align: right;
  display: flex;
  justify-content: space-between;
}

.left-buttons {
  display: flex;
}

.right-buttons {
  display: flex;
}

.menu-modal-footer button {
  padding: 8px 10px;
  border: none;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  margin-left: 5px;
}

.menu-modal-footer .submit-btn {
  background-color: #28a745; /* Green color for submit button */
}

.menu-modal-footer .cancel-btn {
  background-color: #6c757d; /* Gray color for cancel button */
}

.menu-modal-footer button:hover {
  opacity: 0.9;
}

.menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: none;
}

.menu-overlay-active {
  display: block;
}

/* offerslider */
.offers-container {
  font-family: Arial, sans-serif;
  padding: 20px;
  background-color: #0d1117;
  color: #c9d1d9;
}

.offers-heading {
  font-size: 24px;
  color: #c9d1d9;
  margin-bottom: 20px;
}

.offers-slider {
  display: flex;
  flex-wrap: wrap;
  gap: 20px; /* Gap between images */
}

.offer-card {
  background-color: #161b22;
  border-radius: 6px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  width: calc(50% - 20px); /* Half the width of the container minus the gap */
  margin-bottom: 20px; /* Added margin bottom for spacing */
}

.offer-image {
  width: 100%; /* Adjusted image width to fill the card */
  height: auto; /* Auto height to maintain aspect ratio */
  border-radius: 4px;
  margin-bottom: 10px; /* Added margin bottom */
}

.offer-details {
  flex: 1;
}

.offers-actions {
  display: flex;
  justify-content: flex-end; /* Changed alignment to space between */
}

.offers-actions button {
  margin-left: 15px;
  padding: 8px 20px;
  border: none;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
}

.update-btn {
  background-color: #ff7b00; /* Orange color for update button */
}

.delete-btn {
  background-color: #d73a49; /* Red color for delete button */
}

.offers-actions button:hover {
  opacity: 0.9;
}

.offers-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #161b22;
  padding: 20px;
  border-radius: 6px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  color: #c9d1d9;
  width: 400px;
}

.offers-modal-active {
  display: block;
}

.offers-modal-header {
  font-size: 20px;
  margin-bottom: 10px;
  color: #c9d1d9;
  text-align: center;
}

.offers-modal-body {
  display: flex;
  flex-direction: column;
  gap: 10px; /* Gap between input elements */
}

.offers-modal-body label {
  display: block;
  color: #c9d1d9;
}

.offers-modal-body input[type="text"],
.offers-modal-body input[type="file"] {
  width: 100%;
  padding: 8px;
  border: 1px solid #30363d;
  border-radius: 4px;
  background-color: #0d1117;
  color: #c9d1d9;
}

.offers-modal-body input[type="checkbox"] {
  margin-right: 5px;
  background-color: #161b22;
  border: 1px solid #30363d;
  color: #c9d1d9;
}

.offers-modal-body .offers-checkbox-label {
  display: flex;
  align-items: center;
}

.offers-modal-body .offers-image-preview {
  display: block;
  margin-top: 10px;
  width: 100%;
  border-radius: 4px;
}

.offers-modal-footer {
  margin-top: 10px;
  text-align: right;
  display: flex;
  justify-content: space-between;
}

.left-buttons {
  display: flex;
}

.right-buttons {
  display: flex;
}

.offers-modal-footer button {
  padding: 8px 20px; /* Increased padding */
  border: none;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  margin-left: 5px;
}

.offers-modal-footer .submit-btn {
  background-color: #28a745; /* Green color for submit button */
}

.offers-modal-footer .cancel-btn {
  background-color: #6c757d; /* Gray color for cancel button */
}

.offers-modal-footer button:hover {
  opacity: 0.9;
}

.offers-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: none;
}

.offers-overlay-active {
  display: block;
}

.menu-modal-body {
  display: flex;
}

.menu-modal-left,
.menu-modal-right {
  flex: 1;
}

.menu-modal-left {
  margin-right: 20px;
}

.menu-modal-right {
  display: flex;
  flex-direction: column;
}

.menu-modal-right label {
  margin-bottom: 10px;
}

.menu-modal-right img {
  max-width: 100%;
  margin-top: 10px;
}

.custom-file-upload {
  padding: 6px 16px; /* Adjusted padding */
  cursor: pointer;
  color: #ffffff;
  background-color: #0366d6; /* GitHub blue */
  border: 1px solid #0366d6; /* Added border */
  border-radius: 6px;
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Symbol";
  transition: background-color 0.2s ease-in-out;
  display: inline-block;
}

.custom-file-upload:hover {
  background-color: #005cc5; /* Darker blue on hover */
}

/* Hide the original file input */
#file-upload {
  display: none;
}

/* login page  */

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #0d1117;
  color: #fff;
}

.image-container {
  width: 180px;
  height: 100px;
  margin-bottom: 1.5rem;
}

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5%;
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: start;
}


.github-input {
  padding: 0.5rem;
  border: 1px solid #6a737d;
  border-radius: 4px;
  background-color: #2d333b;
  color: #fff;
  margin-bottom: 1rem;
}

.error-message {
  color: #ff6b6b;
  margin-bottom: 1rem;
}

.login-blue-button {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: #0366d6; /* GitHub blue */
  color: #ffffff;
  border: 1px solid #0366d6;
  border-radius: 6px;
  padding: 6px 16px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Symbol";
  transition: background-color 0.2s ease-in-out;
}

.login-blue-button:hover {
  background-color: #005cc5; /* Darker blue on hover */
  cursor: pointer;
}